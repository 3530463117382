














import Vue from 'vue';
import "../../dialogs/ChangePassword.vue";
import {Backend} from "api-frontend";
import {
  LoggedRequest, statisticRequests
} from "mp-common/src/types/api/statisticApi";
import {LocalDateTime} from "js-joda";

export default Vue.extend({
  name: 'Statistic',
  props: {
    user: {},
    backend: {
      type: Backend,
    }
  },
  data() {
    return {
      totalRequestsInLastDay: [] as LoggedRequest[],
      path: ""
    }
  },
  created: function () {
    this.fetchStatistic();
  },
  methods: {
    async fetchStatistic() {
      this.totalRequestsInLastDay = (await this.backend.query(statisticRequests.getInPeriod, {start: LocalDateTime.now().minusDays(1), end: undefined}))?.filter((item) => item.path.includes(this.path)) ?? [];
    },
  }
})

import * as t from "io-ts";
import {TypeOf} from "io-ts";
import {tsLocalDateTime, validateApi} from "api-common";
import {Roles} from "../entities/user";

export const LoggedRequest = t.type({
    time: tsLocalDateTime,
    path: t.string,
})
export type LoggedRequest = TypeOf<typeof LoggedRequest>;

export const statisticRequests = validateApi({
    getAll: {
        path: "/statistic/requests/getAll",
        params: t.undefined,
        queryParams: t.undefined,
        returns: t.array(LoggedRequest),
        roles: [Roles.ACTIVE, Roles.STATISTIC],
    },
    getByPath: {
        path: "/statistic/requests/getByPath",
        params: t.type({
            path: t.string
        }),
        queryParams: t.undefined,
        returns: t.array(LoggedRequest),
        roles: [Roles.ACTIVE, Roles.STATISTIC],
    },
    getInPeriod: {
        path: "/statistic/requests/getInPeriod",
        params: t.type({
            start: tsLocalDateTime,
            end: t.union([tsLocalDateTime, t.undefined])
        }),
        queryParams: t.undefined,
        returns: t.array(LoggedRequest),
        roles: [Roles.ACTIVE, Roles.STATISTIC],
    }
})